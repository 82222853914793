import { useMittEvent } from "@busy-human/vue-component-library";
import mitt from "mitt";
import { ref } from "vue";

type PromptType = "YesNo" | "Choice" | "Ack"

export type Prompt = {
    type: PromptType
    options?: {display: string, value: string}[]
    header: string
    body: string
    timeout?: boolean
}

type PromptOptions = {
    timeout?: boolean
}

type Events = {
    "response_choice": string | null
    "response_boolean": boolean
    "response_ack": void
    "prompt": Prompt
}

const evnts = mitt<Events>();

const pendingPromise = ref<PromiseLike<any> | null>(null);

export function usePrompt() {

    async function promptChoice(header: string, body: string, selectOptions: NonNullable<Prompt['options']>, options?: PromptOptions) {
        if(pendingPromise.value) throw new Error("Already pending prompt!");

        pendingPromise.value = new Promise<string | null>(res => {
            const handler: (r: string | null) => void = r => {
                evnts.off("response_choice", handler);
                res(r);
            };
            evnts.on("response_choice", handler);
        });

        evnts.emit("prompt", {
            type: "Choice",
            header, body, options: selectOptions,
            ... options
        });

        const res: string | null = await pendingPromise.value;

        // eslint-disable-next-line require-atomic-updates
        pendingPromise.value = null;

        return res;
    }

    async function promptConfirm(header: string, body: string, options?: PromptOptions) {
        if(pendingPromise.value) throw new Error("Already pending prompt!");

        pendingPromise.value = new Promise<boolean>(res => {
            const handler: (r: boolean) => void = r => {
                evnts.off("response_boolean", handler);
                res(r);
            };
            evnts.on("response_boolean", handler);
        });

        evnts.emit("prompt", {
            type: "YesNo",
            body, header,
            ... options
        });

        const res: boolean = await pendingPromise.value;

        // eslint-disable-next-line require-atomic-updates
        pendingPromise.value = null;

        return res;
    }

    async function promptAck(header: string, body: string, options?: PromptOptions) {
        if(pendingPromise.value) throw new Error("Already pending prompt!");

        pendingPromise.value = new Promise<void>(res => {
            const handler: () => void = () => {
                evnts.off("response_ack", handler);
                res();
            };
            evnts.on("response_ack", handler);
        });

        evnts.emit("prompt", {
            type: "Ack",
            header, body,
            ... options
        });

        await pendingPromise.value;

        // eslint-disable-next-line require-atomic-updates
        pendingPromise.value = null;
    }

    function usePromptHandler(handler: (prompt: Prompt) => void) {
        useMittEvent(evnts, "prompt", handler);
    }

    return { events: evnts, promptChoice, promptConfirm, promptAck, usePromptHandler };
}