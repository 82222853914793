import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "inset-gutter-10px",
  id: "app"
}
const _hoisted_2 = { class: "no-gutter" }
const _hoisted_3 = { class: "content-and-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_version_manager = _resolveComponent("version-manager")!
  const _component_lockdown_gate = _resolveComponent("lockdown-gate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("main", _hoisted_2, [
      ($setup.FeatureFlags.isFeatureEnabled('GLOBAL_HEADER'))
        ? (_openBlock(), _createBlock($setup["globalHeader"], {
            key: 0,
            "navbar-width": $setup.navbarWidth
          }, null, 8, ["navbar-width"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["view-wrapper", {'no-scroll': !$setup.scrollingEnabled}]),
        style: _normalizeStyle({'padding-top': ($setup.FeatureFlags.isFeatureEnabled('GLOBAL_HEADER') ? $setup.headerHeight : 0), 'height': ($setup.FeatureFlags.isFeatureEnabled('GLOBAL_HEADER') ? `calc(100% - ${$setup.headerHeight})` : '100%')})
      }, [
        _createVNode(_component_router_view, null, {
          default: _withCtx(({ Component, route }: { Component: any; route: any; }) => [
            (route?.meta?.navbar)
              ? (_openBlock(), _createBlock($setup["navbarSlot"], {
                  key: 0,
                  portal: route.meta.navbar
                }, null, 8, ["portal"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                class: "content",
                key: route.meta.usePathKey ? route.path : undefined,
                style: _normalizeStyle({ 'margin-left': (route?.meta?.navbar ? $setup.navbarWidth : 0), 'transition': 'margin .3s ease'})
              }, null, 8, ["style"])),
              ($setup.FeatureFlags.isFeatureEnabled('GLOBAL_FOOTER'))
                ? (_openBlock(), _createBlock($setup["globalFooter"], {
                    key: 0,
                    class: "global-footer",
                    style: _normalizeStyle({'margin-left': (route?.meta?.navbar ? $setup.navbarWidth : 0), 'transition': 'margin .3s ease'})
                  }, null, 8, ["style"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ], 6),
      ($setup.builders.userBuilderNotFound)
        ? (_openBlock(), _createBlock($setup["BuilderMissingModal"], {
            key: 1,
            onClose: _cache[0] || (_cache[0] = ($event: any) => ($setup.builders.userBuilderNotFound = false))
          }))
        : _createCommentVNode("", true),
      ($setup.showAdminPanel)
        ? (_openBlock(), _createBlock($setup["AdminPopup"], {
            key: 2,
            onClose: _cache[1] || (_cache[1] = ($event: any) => ($setup.showAdminPanel = false)),
            onOpenTripAssignment: _cache[2] || (_cache[2] = ($event: any) => ($setup.showTripAssignmentPopup = true))
          }))
        : _createCommentVNode("", true)
    ]),
    ($setup.showTripAssignmentPopup)
      ? (_openBlock(), _createBlock($setup["TripAssigmentPopup"], {
          key: 0,
          class: "trip-assigment",
          message: $setup.tripAssignmentMessage,
          onClose: _cache[3] || (_cache[3] = ($event: any) => {$setup.showTripAssignmentPopup = false; $setup.tripAssignmentMessage = undefined})
        }, null, 8, ["message"]))
      : _createCommentVNode("", true),
    _createVNode($setup["PromptHandler"]),
    _createVNode(_component_version_manager),
    _createVNode(_component_lockdown_gate)
  ]))
}