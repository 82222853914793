/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines-per-function */
import { DataInstance, Destinations, Trips } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useTrips } from "./trips";

// import FillerData from '@/util/filler-trips'

export const useDestinations = defineStore('destinations', () => {
    const _destinations = ref<Record<string, DataInstance<Destinations.Model>>>({});
    const destinations = computed(() => _destinations.value);
    const loaded = ref(false);
    const waitingPromises = ref<(() => void)[]>([]);

    // we can fetch all the available trips here
    // _trips.value = FillerData;
    Destinations.Collection.fetchAll().then(items => {
        const tmp: typeof _destinations.value = {};
        for(const destination of items.items) {
            const destinationData = destination.data();
            // if(!destinationData.showOnWeb) continue;
            tmp[destination.$id] = destinationData;
        }
        _destinations.value = tmp;

        loaded.value = true;
        waitingPromises.value.forEach(res => res());
        waitingPromises.value.length = 0;
    });

    function waitForReady() {
        if(!loaded.value) return new Promise<void>(res => {waitingPromises.value.push(res);});
        else return Promise.resolve();
    }

    const destinationSlots = computed(() => {
        const tripsStore = useTrips();
        const slots: Record<string, DataInstance<Trips.Model>[]> = {};
        console.log("TripsStore", tripsStore.asArray);
        Object.keys(_destinations.value).forEach(destinationId => {
            slots[destinationId] = tripsStore.asArray.filter(slot => slot.destination === destinationId);
        });
        return slots;
    });

    const destinationLengths = computed(() => {
        const lengths: Record<string, number | undefined> = {};
        Object.keys(_destinations.value).forEach(destinationId => {
            const slot = destinationSlots.value[destinationId]?.[0];
            lengths[destinationId] = slot ? Trips.GetTripLength(slot) : undefined;
        });
        return lengths;
    });


    const asArray = computed(() => Object.values(_destinations.value));
	const visible = computed(() => asArray.value.filter(d => d.showOnWeb));

    return { destinations, asArray, destinationSlots, destinationLengths, waitForReady, loaded, visible };
});