import { DataInstance, Flights } from "@busy-human/hxp-library";
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import { useBuilderStore } from "./builder";

export const useFlights = defineStore('flights', () => {
    const _flights = ref<Record<string, DataInstance<Flights.Model>>>({});
    const flights = computed(() => _flights.value);
    const builderStore = useBuilderStore();

	const builderFlights = reactive<Record<string, DataInstance<Flights.Model>[]>>({});

    const loaded = ref(false);
    
	async function fetchFlightsForTrip(tripId: string): Promise<Record<string, DataInstance<Flights.Model>>> {
        try {
            loaded.value = false;

            // Query Firestore for flights matching the tripId
			const tripFlights = await Flights.Collection.subset((_, q) => q.where("trip", "==", tripId).toQuery()).fetch();

            const fetchedFlights: Record<string, DataInstance<Flights.Model>> = {};
            tripFlights.forEach((doc) => {
                fetchedFlights[doc.$id] = doc.data();
            });

            loaded.value = true;
            return fetchedFlights;
        } catch (error) {
            console.error("Error fetching flights from Firestore:", error);
            return {};
        }
    }

    watch(
        () => builderStore.assignedTrip,
        async (newTrip, prevTrip) => {
			if(newTrip === prevTrip) return;
            if (newTrip) {
                _flights.value = await fetchFlightsForTrip(newTrip) ?? {};
            } else {
                _flights.value = {};
            }
        },
        { immediate: true }
    );


	const waitingResolves = ref<(() => void)[]>([]);
	const ready = ref(false);
	watch(() => builderStore.builderRecords, async records => {
		for(const record of Object.values(records)) {
			try {
				if(record.tripAssigned) {
					const f = await fetchFlightsForTrip(record.tripAssigned);
					builderFlights[record.$id] = Object.values(f);
				}
			} catch (e) {
				console.error(e);
			}
		}
		ready.value = true;
		waitingResolves.value.forEach(res => res());
		waitingResolves.value.length = 0;
	}, { immediate: true });

	function waitForReady() {
		if(ready.value) return Promise.resolve();
		else return new Promise<void>(res => { waitingResolves.value.push(res); });
	}

	const builderHasFlightChanges = computed(() => Object.fromEntries(Object.entries(builderFlights).map(([builderId, fs]) => {
		const record = builderStore.builderRecords[builderId];

		if(!record) return [builderId, false] as const;

		const hasChanges = fs.some(f => {
			if(!record.flightInfoLastViewed) return true;
			if(!f.flightChangeDate) return false;
			return new Date(record.flightInfoLastViewed).getTime() < new Date(f.flightChangeDate).getTime();
		});

		return [builderId, hasChanges] as const;
	})));


    return { flights, loaded, fetchFlightsForTrip, builderHasFlightChanges, waitForReady };

});