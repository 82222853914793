import { computed, ref, watch } from "vue";
import { defineStore } from "pinia";

import {DataInstance, Builders, BuilderDocuments, Destinations, Trips, Season, AssignedChecklistItem, ChecklistDefinitions} from '@busy-human/hxp-library';
import {useBuilderStore} from './builder';
import {useTrips} from './trips';
import {useDestinations} from './destinations';
import type { checklistPrimaryNode, subChecklistNode } from '../components/Navbar/checklist-types';
import { categoryToPath, checklistBaseline, pageIdToPath, pathToCategory, pathToTaskId, taskIdToPath} from "../components/builder-portal-nav-options/nav-options";
import { useChecklistStore } from "./assignedChecklist";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useInvoices } from "./invoice";
import { useSeasonFeature } from "@/composables/useSeasonFeature";
export type States = 'none' | 'uploading' | 'waiting' | 'submitting' | 'done' | 'error';

// eslint-disable-next-line max-lines-per-function
export const useBuilderPortalStore = defineStore('builder-portal', () => {

    const isReady = ref<boolean>(false);

    const now = new Date();

    const builders = useBuilderStore();
    const destinations = useDestinations();
    const trips = useTrips();
    const checklistStore = useChecklistStore();
    const invoices = useInvoices();
    const checklistEnabled = useSeasonFeature(Season.Features.CHECKLIST);

    enum BuilderType {
        "Builder" = "builder",
        "ParentBuilder" = "parentBuilder",
        "AtHomeParent" = "atHomeParent",
        "TripLeader" = "tripLeader",
        "LocalCoordinator" = "localCoordinator"
    }
    
    const tripId = computed(()=> builders.currentBuilderRecord?.tripAssigned);

    const userTrip = computed(()=> (tripId.value ? trips.trips[tripId.value] : null));

    function tripByUserId(builderId:string){
        const builderRecord = builders.recordByBuilderId(builderId);
        if(builderRecord?.tripAssigned){
            const id = builderRecord.tripAssigned;
            if(id){
                return trips.trips[id];
            }
        }
        return null;
    }

    const userDestination = computed(()=> (userTrip.value?.destination ? destinations.destinations[userTrip.value.destination] : null));

    const userDestinationRequiresVaccine = computed(() => Object.values(checklistStore.builderChecklist?.[builders.currentBuilder?.$id || ''] || {}).filter((item) => item.completionMethod === 'vaccination-submitted').length > 0);

    function getChecklistItems(builderId: string){
        const userChecklistInfo = checklistStore.builderChecklist?.[builderId || '']; 
        return Object.values(userChecklistInfo || {});
    }

    function getChecklistContent(builderId: string | undefined){
        let checklist:checklistPrimaryNode[] = [];

        let baseChecklist = cloneDeep(checklistBaseline);

        if(builders.withRecordAsArray.length <= 1){
            baseChecklist = baseChecklist.filter((checklistItem) => checklistItem.pathName !== 'household');
        }

        const checklistNode = baseChecklist.find((node) => node.pathName === 'checklistStatus' && node.subList !== undefined);
        if(checklistNode && builderId){
            const userChecklistInfo = getChecklistItems(builderId);

            const checklistPages = new Set<string>();
            Object.values(userChecklistInfo || {}).forEach((checklistItem) => {
                if(!(checklistItem.completionMethod === 'user-checks-off' && checklistItem.optional)){
                    const path = categoryToPath[checklistItem.category];
                    if(path){
                        checklistPages.add(path);
                    }
                    
                }
            });

            const subList: subChecklistNode[] = [];

            if(!checklistPages.has('vaccines')){
                subList.push({description: "Vaccines(optional)", pathName:"vaccines"});
            }

            checklistPages.forEach((pageId) => subList.push(pageIdToPath[pageId]));

            const pageIds = Object.keys(pageIdToPath);
            subList.sort((a, b) => (pageIds.indexOf(a.pathName!) || 0) - (pageIds.indexOf(b.pathName!) || 0));

            checklistNode.subList = subList;
        }

        checklist = baseChecklist;

        return checklist;
    }

    const userChecklistItems = computed(() => (builders.currentBuilder?.$id ? getChecklistItems(builders.currentBuilder.$id) : []));

    const checklistContent = computed<checklistPrimaryNode[]>(()=> getChecklistContent(builders.currentBuilder?.$id));

    const currentBuilderCanAccessPage = (toPath: string | undefined) => {
        if(checklistEnabled.value === Season.FeatureState.DISABLED) return false;
        if(!toPath) return false;
        const checklist = getChecklistContent(builders.currentBuilder?.$id);
        const matchingPaths = checklist.filter((item) => {
            if(item.pathName === toPath) {return true;}
            else if(item.subList){
                return item.subList.filter((subItem) => subItem.pathName === toPath).length > 0;

            }else {return false;}
        });
        return matchingPaths.length > 0;
    };

    // eslint-disable-next-line complexity
    const getChecklistPathStatus = (checklistPath: string) => {
        if(Object.keys(pathToCategory).includes(checklistPath)){
            const category = pathToCategory[checklistPath];

            if(category === ChecklistDefinitions.ChecklistCategory.vaccination && !userDestinationRequiresVaccine.value) return 'completed';

            const categoryItems = userChecklistItems.value.filter((item) => item.category === category);
            const completedCategoryItems = categoryItems.filter((item) => item.status === 'completed');

            if(categoryItems.length > 0 && completedCategoryItems.length === categoryItems.length) return 'complete';

            const mainCategoryItems = categoryItems.filter((item) => item.completionMethod !== 'user-checks-off');
            const now = new Date();

            const overdueItems = mainCategoryItems.filter((item) =>  new Date(item.resolvedAbsoluteDueDate).valueOf() < now.valueOf());
            if(overdueItems.length > 0) return 'overdue';

            const delayedItems = mainCategoryItems.filter((item) => item.resolvedCantStartBeforeDate && now.valueOf() < new Date(item.resolvedCantStartBeforeDate).valueOf());
            if(delayedItems.length > 0) return 'delayed';

        }else if(checklistPath === 'tripPayment'){
            if(invoices.builderTotalRemaining <= 0) return 'complete';
        }else if(checklistPath === 'builderInfo'){
            return 'complete';
        }else if(checklistPath === 'policies'){
            return 'complete';
            //TODO
        }else if(checklistPath === 'checklistStatus'){
            if(userChecklistItems.value.length > 0 && userChecklistItems.value.filter((item) => !item.completionDate).length === 0) return 'complete';
        }
        
        if(builders.currentBuilder?.$id && getBuilderChecklistProgress(builders.currentBuilder.$id) === 100) return 'complete';
        return 'default';
    };

    async function updateChecklistItem(status: "completed" | "in-progress" | "not-started" | "issue", assignedChecklistId: string, builderId?: string): Promise<string | null>{
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const id = builderId || builders.currentBuilder?.$id;
        if(!id) return null;
        const result = await checklistStore.updateChecklistItem(id, assignedChecklistId, status);
        return result;
    }

    async function updateChecklistTasks(status: "completed" | "in-progress" | "not-started" | "issue", checklistPath: string, builderId?: string){
        if(checklistEnabled.value !== Season.FeatureState.ENABLED) throw new Error('Updating Checklist Items has been disabled for this season');
        const id = builderId || builders.currentBuilder?.$id;
        if(!id) return null;
        const checklistTaskId = pathToTaskId?.[checklistPath];
        if(!checklistTaskId) return null;
        const checklistItems = getChecklistItems(id);

        const promises: Promise<any>[] = [];
        checklistItems.filter((item) => item.completionMethod === checklistTaskId).forEach((item) => {
            promises.push(checklistStore.updateChecklistItem(id, item.$id, status));
        });
        const results = await Promise.allSettled(promises);
        return results;
    }

    const userCancelled = computed(() => builders.currentBuilderRecord?.status === 'canceled');

    const userWaitlisted = computed(() => !!builders.currentBuilderRecord?.onWaitlist);

    const userPreLottery = computed(() => (isReady.value && !builders.currentBuilderRecord?.booked && !builders.currentBuilderRecord?.onWaitlist));
    
    const getTaskDueDate = (path: string) => {
        const taskId = pathToTaskId?.[path];

        if(checklistStore.useFakeChecklist) return null;

        if(!taskId) return null;

        if(taskId === 'vaccination-submitted' && !userDestinationRequiresVaccine.value) return null;
        const relevantItems = userChecklistItems.value.filter((item) => item.completionMethod === taskId);
        if(relevantItems.length === 0) return null;

        //TODO: Maybe compare due dates here and take the earliest date if theres multiple
        return moment(relevantItems[0].resolvedAbsoluteDueDate).format('MMM DD');
    };

    
    const remainingDueDates = computed(() => {
        const dueDates: Date[] = [];
        if(!checklistStore.useFakeChecklist){
            userChecklistItems.value.forEach((checklistItem) => {
                if(checklistItem.resolvedAbsoluteDueDate && checklistItem.status !== 'completed'){
                    dueDates.push(new Date(checklistItem.resolvedAbsoluteDueDate));
                }
            });
        }

        return dueDates;
    });
    const nextDueDate = computed(() => {
        let lowestDiff: number | null = null;
        let lowestDiffIdx: number | null = null;
        remainingDueDates.value.forEach((dueDate, idx) => {
            const diff =  dueDate.valueOf() - now.valueOf();
            if(lowestDiff === null || diff < lowestDiff){
                lowestDiff = diff;
                lowestDiffIdx = idx;
            }
        });
        return lowestDiffIdx !== null ? remainingDueDates.value[lowestDiffIdx] : null;
    });

    const getNextDueDate = (builderId: string) => {
        const checlistItems = getChecklistItems(builderId);
        const dueDates: Date[] = [];
        checlistItems.forEach((checklistItem) => {
            if(checklistItem.resolvedAbsoluteDueDate){
                dueDates.push(new Date(checklistItem.resolvedAbsoluteDueDate));
            }
        });

        let lowestDiff: number | null = null;
        let lowestDiffIdx: number | null = null;
        dueDates.forEach((dueDate, idx) => {
            const diff =  dueDate.valueOf() - now.valueOf();
            if(lowestDiff === null || diff < lowestDiff){
                lowestDiff = diff;
                lowestDiffIdx = idx;
            }
        });
        return lowestDiffIdx !== null ? dueDates[lowestDiffIdx] : null;
    };

    function getBuilderChecklistProgress(builderId: string){
        if(!isReady.value) return undefined;

        const checklistItems = getChecklistItems(builderId).filter((item) => !item.name.toLowerCase().includes('(optional)'));

        let checklistCompletionPercent = 0.00;

        if(checklistItems.length > 0){
            const completedItems = checklistItems.filter((item) => item.status === 'completed');
            // console.log("Checklist Items", checklistItems);

            checklistCompletionPercent = parseFloat(`${ completedItems.length}`) / parseFloat(`${ checklistItems.length}`);
        }

        let paymentPercent = 0.00;

        const invoice = invoices.getCurrentInvoice(builderId);
        if(invoice){
            if(invoice.totalOwed) paymentPercent = Math.min(invoice.totalPaid / invoice.totalOwed, 1);
            else if(invoice.totalOwed === 0) paymentPercent = 1;
        }

        return parseInt((((checklistCompletionPercent + paymentPercent) / 2) * 100).toFixed(0));
    }

    const checklistProgress = computed(() => (builders.currentBuilder ? getBuilderChecklistProgress(builders.currentBuilder?.$id) : 0));

    Promise.all([builders.waitForReady()]).then(()=> {
        isReady.value = true;
        waitingResolves.forEach(func => func());
        waitingResolves.length = 0;
    });

    const waitingResolves: (() => void)[] = [];

    const waitForReady = async () => {
        if(isReady.value) {return true;}
        else {
            return new Promise<void>(res => waitingResolves.push(res));
        }
    };

    return {
        waitForReady,

        userCancelled, userWaitlisted, userPreLottery,

        userTrip, 
        userDestination,
        
        userChecklistItems,
        checklistContent,
        getBuilderChecklistProgress,
        checklistProgress,
        remainingDueDates,
        nextDueDate,
        getNextDueDate,

        getChecklistPathStatus,
        updateChecklistItem,
        updateChecklistTasks,
        currentBuilderCanAccessPage,
        getTaskDueDate,
        tripByUserId
        };
});